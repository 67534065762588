<template>
	<div style="">
		<div class="advantage" v-if="windowWidth>=1200">
			<div class="at_titleDiv">
				<div class="at_td_title">{{$t('fpcBatch.advantage.title')}}</div>
				<div class="at_td_text">{{$t('fpcBatch.advantage.text')}}</div>
			</div>
			<div class="at_contentDiv">
				<div class="at_cd_left">
					<div class="at_cd_le_div">
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index1.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content1.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content1.text1')}}</div>
								<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content1.text2')}}</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index2.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content2.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content2.text1')}}</div>
								<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content2.text2')}}</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index3.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content3.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content3.text1')}}</div>
								<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content3.text2')}}</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index4.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content4.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content4.text1')}}</div>
								<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content4.text2')}}</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index5.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content5.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content5.text1')}}</div>
								<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content5.text2')}}</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index6.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content6.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content6.text1')}}</div>
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="at_cd_right"  :style="'background-image:' + `url(${require('@/assets/img/environment1.jpg')})`"></div>
			</div>
			
			<div class="at_buttonDiv">
				<div class="at_bd_text">{{$t('fpcBatch.advantage.button')}}</div>
			</div>
		</div>
		
		<div class="advantage2" v-if="windowWidth<=1199">
			<div class="at_titleDiv">
				<div class="at_td_title">{{$t('fpcBatch.advantage.title')}}</div>
				<div class="at_td_text">{{$t('fpcBatch.advantage.text')}}</div>
			</div>
			<div class="at_contentDiv">
				<div class="at_cd_left">
					<div class="at_cd_le_div">
						<div class="at_cd_le_di_contentDiv">
							<div>
								<div class="at_cd_le_di_cd_titleDiv">
									<div class="at_cd_le_di_cd_td_line"></div>
									<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index1.png')" :fit="'fill'"></el-image>
									<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content1.title')}}</div>
								</div>
								<div class="at_cd_le_di_cd_contentDiv">
									<div>{{$t('fpcBatch.advantage.content1.text1')}}</div>
									<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content1.text2')}}</div>
								</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div>
								<div class="at_cd_le_di_cd_titleDiv">
									<div class="at_cd_le_di_cd_td_line"></div>
									<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index2.png')" :fit="'fill'"></el-image>
									<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content2.title')}}</div>
								</div>
								<div class="at_cd_le_di_cd_contentDiv">
									<div>{{$t('fpcBatch.advantage.content2.text1')}}</div>
									<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content2.text2')}}</div>
								</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div>
								<div class="at_cd_le_di_cd_titleDiv">
									<div class="at_cd_le_di_cd_td_line"></div>
									<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index3.png')" :fit="'fill'"></el-image>
									<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content3.title')}}</div>
								</div>
								<div class="at_cd_le_di_cd_contentDiv">
									<div>{{$t('fpcBatch.advantage.content3.text1')}}</div>
									<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content3.text2')}}</div>
								</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div>
								<div class="at_cd_le_di_cd_titleDiv">
									<div class="at_cd_le_di_cd_td_line"></div>
									<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index4.png')" :fit="'fill'"></el-image>
									<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content4.title')}}</div>
								</div>
								<div class="at_cd_le_di_cd_contentDiv">
									<div>{{$t('fpcBatch.advantage.content4.text1')}}</div>
									<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content4.text2')}}</div>
								</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div>
								<div class="at_cd_le_di_cd_titleDiv">
									<div class="at_cd_le_di_cd_td_line"></div>
									<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index5.png')" :fit="'fill'"></el-image>
									<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content5.title')}}</div>
								</div>
								<div class="at_cd_le_di_cd_contentDiv">
									<div>{{$t('fpcBatch.advantage.content5.text1')}}</div>
									<div style="padding-top: 10px;">{{$t('fpcBatch.advantage.content5.text2')}}</div>
								</div>
							</div>
						</div>
						
						<div class="at_cd_le_di_contentDiv">
							<div class="at_cd_le_di_cd_titleDiv">
								<div class="at_cd_le_di_cd_td_line"></div>
								<el-image class="at_cd_le_di_cd_td_icon" :src="require('@/assets/img/index6.png')" :fit="'fill'"></el-image>
								<div class="at_cd_le_di_cd_td_title">{{$t('fpcBatch.advantage.content6.title')}}</div>
							</div>
							<div class="at_cd_le_di_cd_contentDiv">
								<div>{{$t('fpcBatch.advantage.content6.text1')}}</div>
							</div>
						</div>
					</div>
				</div>
				
				
				<div class="at_cd_right">
					<el-image :src="require('@/assets/img/environment1.jpg')" :fit="'fill'"></el-image>
				</div>
			</div>
			
			<div class="at_buttonDiv">
				<div class="at_bd_text">{{$t('fpcBatch.advantage.button')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
				
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.advantage{
			padding: 40px 200px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				display: flex;
				width: 100%;
				
				.at_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 2.6vw;
									height: 1.9vw;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 1vw;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 0.6vw;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.advantage{
			padding: 40px 200px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				display: flex;
				width: 100%;
				
				.at_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 2.6vw;
									height: 1.9vw;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 1vw;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 0.6vw;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.advantage{
			padding: 40px 140px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				display: flex;
				width: 100%;
				
				.at_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 2.6vw;
									height: 1.9vw;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 1vw;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 0.6vw;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.advantage{
			padding: 40px 30px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				display: flex;
				width: 100%;
				
				.at_cd_left{
					width: 50%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 2.6vw;
									height: 1.9vw;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 1vw;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 0.6vw;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 50%;
					position: relative;
					background-size: cover;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					width: 9vw;
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.advantage2{
			padding: 40px 30px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				width: 100%;
				
				.at_cd_left{
					width: 100%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 2.6vw;
									height: 1.9vw;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 1vw;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 0.6vw;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 100%;
					position: relative;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 1vw;
					padding: 2vw 4vw;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.advantage2{
			padding: 40px 30px;
			
			.at_titleDiv{
				text-align: center;
				
				.at_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.at_td_text{
					font-size: 18px;
					color: #aaa;
					line-height: 28px;
				}
			}
			
			.at_contentDiv{
				width: 100%;
				
				.at_cd_left{
					width: 100%;
					padding-top: 60px;
					
					.at_cd_le_div{
						display: flex;
						flex-wrap: wrap;
						padding-top: 20px;
						
						.at_cd_le_di_contentDiv{
							width: 50%;
							padding-bottom: 30px;
							
							.at_cd_le_di_cd_titleDiv{
								display: flex;
								align-items: center;
								padding-bottom: 20px;
								position: relative;
								
								.at_cd_le_di_cd_td_line{
									position: absolute;
									width: 140px;
									height: 2px;
									background: #d1102d;
									bottom: 0;
								}
								
								.at_cd_le_di_cd_td_icon{
									width: 30px;
								}
								
								.at_cd_le_di_cd_td_title{
									padding-left: 10px;
									font-size: 12px;
								}
							}
							
							.at_cd_le_di_cd_contentDiv{
								padding-top: 20px;
								font-size: 12px;
							}
						}
					}
				}
				
				.at_cd_right{
					width: 100%;
					position: relative;
					margin-top: 20px;
				}
			}
			
			.at_buttonDiv{
				display: flex;
				justify-content: center;
				padding-top: 20px;
				
				.at_bd_text{
					color: #fff;
					font-size: 12px;
					padding:10px;
					border-radius: 40px;
					text-align: center;
					margin-top: 20px;
					width: 100px;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
				}
			}
		}
	}
	
</style>