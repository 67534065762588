<template>
	<div>
		<div class="service" v-if="windowWidth>=1200">
			<div class="sv_titleDiv">
				<div class="sv_td_title">{{$t('fpcBatch.service.title')}}</div>
				<div class="sv_td_text">{{$t('fpcBatch.service.text')}}</div>
			</div>
			
			<div class="sv_contentDiv">
				<div class="sv_cd_tabs">
					<div class="sv_cd_tb_name" :style="tabsIndex==index ? 'color: #fff;font-weight: bold;background: #d1102d;' : 'background: #fff;'" v-for="(item,index) in $t('fpcBatch.service.tabs')" :key="index" @mouseenter="tabsMoseleave(index)">{{item.name}}</div>
				</div>
				
				<div class="sv_cd_contentDiv" v-for="(item,index) in $t('fpcBatch.service.tabs')" :key="index" v-if="index==tabsIndex">
					<div class="sv_cd_cd_left">
						<div class="sv_cd_cd_le_text">{{$t('fpcBatch.service.text2')}}</div>
						<table class="sv_cd_cd_le_table">
							<tbody class="sv_cd_cd_le_tb_tbody">
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text3')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.plateThickness}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text4')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.ResistanceWelding}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text5')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.reinforce}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text6')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.copperThickness}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text7')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.technology}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text8')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.lineWidth}}</td>
								</tr>
							</tbody>
						</table>
						<div class="sv_cd_cd_le_text2">{{$t('fpcBatch.service.text9')}}：</div>
						<div class="sv_cd_cd_le_text3">{{item.content.structure}}</div>
						
						<div class="sv_cd_cd_le_text4">{{$t('fpcBatch.service.text10')}}：</div>
						<el-image style="padding-top: 10px;" :src="require('@/assets/img/tool-icons.png')" :fit="'contain'"></el-image>
					</div>
					<div class="sv_cd_cd_right">
						<el-image class="sv_cd_cd_ri_img" :src="item.content.img" :fit="'cover'"></el-image>
					</div>
				</div>
			</div>
		</div>
		
		<div class="service2" v-if="windowWidth<=1199">
			<div class="sv_titleDiv">
				<div class="sv_td_title">{{$t('fpcBatch.service.title')}}</div>
				<div class="sv_td_text">{{$t('fpcBatch.service.text')}}</div>
			</div>
			
			<div class="sv_contentDiv">
				<div class="sv_cd_tabs">
					<div class="sv_cd_tb_name" :style="tabsIndex==index ? 'color: #fff;font-weight: bold;background: #d1102d;' : 'background: #fff;'" v-for="(item,index) in $t('fpcBatch.service.tabs')" :key="index" @mouseenter="tabsMoseleave(index)">{{item.name}}</div>
				</div>
				
				<div class="sv_cd_contentDiv" v-for="(item,index) in $t('fpcBatch.service.tabs')" :key="index" v-if="index==tabsIndex">
					<div class="sv_cd_cd_right">
						<el-image class="sv_cd_cd_ri_img" :src="item.content.img" :fit="'contain'"></el-image>
					</div>
					<div class="sv_cd_cd_left">
						<div class="sv_cd_cd_le_text">{{$t('fpcBatch.service.text2')}}</div>
						<table class="sv_cd_cd_le_table">
							<tbody class="sv_cd_cd_le_tb_tbody">
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text3')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.plateThickness}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text4')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.ResistanceWelding}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text5')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.reinforce}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text6')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.copperThickness}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text7')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.technology}}</td>
								</tr>
								<tr class="sv_cd_cd_le_tb_td_tr">
									<td class="sv_cd_cd_le_tb_td_tr_td1">{{$t('fpcBatch.service.text8')}}：</td>
									<td class="sv_cd_cd_le_tb_td_tr_td2">{{item.content.lineWidth}}</td>
								</tr>
							</tbody>
						</table>
						<div class="sv_cd_cd_le_text2">{{$t('fpcBatch.service.text9')}}：</div>
						<div class="sv_cd_cd_le_text3">{{item.content.structure}}</div>
						
						<div class="sv_cd_cd_le_text4">{{$t('fpcBatch.service.text10')}}：</div>
						<el-image style="padding-top: 10px;" :src="require('@/assets/img/tool-icons.png')" :fit="'contain'"></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabsIndex:0,
				tabs:[{
					name:'单面板层金',
					content:{
						plateThickness:"0.1mm",
						ResistanceWelding:"黄膜白字",
						reinforce:"无补强",
						copperThickness:"1/2OZ",
						technology:"沉金工艺",
						lineWidth:"≥3mil",
						structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
						img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer1.jpg',
					}
				},
				{
					name:'双面沉金',
					content:{
						plateThickness:"0.13mm",
						ResistanceWelding:"黑膜白字",
						reinforce:"无补强",
						copperThickness:"0.5OZ",
						technology:"沉金工艺",
						lineWidth:"≥3mil",
						structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
						img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer2.jpg',
					}
				},
				{
					name:'四层沉金',
					content:{
						plateThickness:"0.23mm",
						ResistanceWelding:"绿膜白字",
						reinforce:"无补强",
						copperThickness:"0.5OZ",
						technology:"沉金工艺",
						lineWidth:"≥3mil",
						structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
						img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer3.jpg',
					}
				},
				{
					name:'六层软板沉金',
					content:{
						plateThickness:"0.23mm",
						ResistanceWelding:"绿膜白字",
						reinforce:"无补强",
						copperThickness:"0.5OZ",
						technology:"沉金工艺",
						lineWidth:"≥4mil",
						structure:"双面胶+低损耗黄色覆盖膜+（线路铜+胶+高频介质 聚酰亚胺基材+胶+线路铜）+低损耗黄色覆盖膜",
						img:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-layer4.jpg',
					}
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//-------------------鼠标移入移出-----------------------------------
			tabsMoseleave:function(index){
				this.tabsIndex=index;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.service{
			padding: 40px 200px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					
					.sv_cd_tb_name{
						font-size: 0.8vw;
						padding: 1vw 1.6vw;
						margin: 0 10px;
						cursor: pointer;
					}
				}
				
				.sv_cd_contentDiv{
					display: flex;
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						width: 30%;
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 0.6vw;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 0.6vw;
						}
					}
					
					.sv_cd_cd_right{
						width: 70%;
						height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.service{
			padding: 40px 200px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					
					.sv_cd_tb_name{
						font-size: 0.8vw;
						padding: 1vw 1.6vw;
						margin: 0 10px;
						cursor: pointer;
					}
				}
				
				.sv_cd_contentDiv{
					display: flex;
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						width: 30%;
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 0.6vw;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 0.6vw;
						}
					}
					
					.sv_cd_cd_right{
						width: 70%;
						height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.service{
			padding: 40px 140px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					
					.sv_cd_tb_name{
						font-size: 0.8vw;
						padding: 1vw 1.6vw;
						margin: 0 10px;
						cursor: pointer;
					}
				}
				
				.sv_cd_contentDiv{
					display: flex;
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						width: 30%;
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 0.6vw;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 0.6vw;
						}
					}
					
					.sv_cd_cd_right{
						width: 70%;
						height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.service{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					
					.sv_cd_tb_name{
						font-size: 0.8vw;
						padding: 1vw 1.6vw;
						margin: 0 10px;
						cursor: pointer;
					}
				}
				
				.sv_cd_contentDiv{
					display: flex;
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						width: 30%;
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 0.6vw;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 0.6vw;
						}
					}
					
					.sv_cd_cd_right{
						width: 70%;
						height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.service2{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 16px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					
					.sv_cd_tb_name{
						font-size: 0.8vw;
						padding: 1vw 1.6vw;
						margin: 0 10px;
						cursor: pointer;
					}
				}
				
				.sv_cd_contentDiv{
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 0.6vw;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 0.6vw;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 0.6vw;
						}
					}
					
					.sv_cd_cd_right{
						// height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.service2{
			padding: 40px 30px;
			background: #f5f5f5;
			
			.sv_titleDiv{
				text-align: center;
				
				.sv_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #333333;
				}
				
				.sv_td_text{
					font-size: 16px;
					color: #333333;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
			
			.sv_contentDiv{
				width: 100%;
				position: relative;
				padding: 1vw;
				
				.sv_cd_tabs{
					display: flex;
					justify-content: center;
					width: 100%;
					align-items: center;
					flex-wrap: wrap;
					
					.sv_cd_tb_name{
						font-size: 12px;
						padding: 10px 16px;
						margin: 0 10px;
						cursor: pointer;
						margin-bottom: 20px;
					}
				}
				
				.sv_cd_contentDiv{
					width: 100%;
					margin-top: 20px;
					
					.sv_cd_cd_left{
						background: #fff;
						padding: 20px 30px;
						height: 450px;
						
						.sv_cd_cd_le_text{
							font-size: 12px;
						}
						 
						.sv_cd_cd_le_table{
							border-collapse: collapse;
							width: 100%;
							font-size: 12px;
							margin-top: 12px;
							
							.sv_cd_cd_le_tb_tbody{
								display: table-row-group;
								vertical-align: middle;
								border-color: inherit;
								
								.sv_cd_cd_le_tb_td_tr{
									display: table-row;
									vertical-align: inherit;
									border-color: inherit;
									
									.sv_cd_cd_le_tb_td_tr_td1{
										background: #f7f7f7;
										padding-left: 20px;
										text-align: left;
										padding-right: 0;
										box-sizing: border-box;
										border: solid 1px #ececec;
									}
									
									.sv_cd_cd_le_tb_td_tr_td2{
										border: solid 1px #ececec;
										background: #fff;
										width: 50%;
										text-align: right;
										padding: 3px 20px 3px 0;
									}
								}
							}
						}
						
						.sv_cd_cd_le_text2{
							line-height: 30px;
							padding-top: 10px;
							font-size:12px;
						}
						
						.sv_cd_cd_le_text3{
							line-height: 20px;
							font-size: 12px;
						}
						
						.sv_cd_cd_le_text4{
							line-height: 30px;
							padding-top: 20px;
							font-size: 12px;
						}
					}
					
					.sv_cd_cd_right{
						// height: 450px;
						
						.sv_cd_cd_ri_img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	
</style>