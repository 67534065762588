<template>
	<div>
		<div class="precisionEquipment" :style="'background-image:' + `url(${bgImg})`" v-if="windowWidth>=1200">
			<div class="pe_titleDiv">
				<div class="pe_td_title">{{$t('fpcBatch.precisionEquipment.title')}}</div>
				<div class="pe_td_text">{{$t('fpcBatch.precisionEquipment.text1')}}</div>
			</div>
			
			<div class="shell">
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content1')}}</div>
						<div class="box_line"></div>
					</div>
					<div class="mask"></div>
					<img :src="img1" alt="">
				</div>
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content2')}}</div>
						<div class="box_line"></div>
					</div>
					<div class="mask"></div>
					<img :src="img2" alt="">
				</div>
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content3')}}</div>
						<div class="box_line"></div>
					</div>
					<div class="mask"></div>
					<img :src="img3" alt="">
				</div>
				<div class="box">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content4')}}</div>
						<div class="box_line"></div>
					</div>
					<div class="mask"></div>
					<img :src="img3" alt="">
				</div>
			</div>
		</div>
		
		<div class="precisionEquipment2" :style="'background-image:' + `url(${bgImg})`" v-if="windowWidth<=1199">
			<div class="pe_titleDiv">
				<div class="pe_td_title">{{$t('fpcBatch.precisionEquipment.title')}}</div>
				<div class="pe_td_text">{{$t('fpcBatch.precisionEquipment.text1')}}</div>
			</div>
			
			<div class="shell2">
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content1')}}</div>
						<div class="box_line"></div>
					</div>
					<img :src="img1" alt="">
				</div>
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content2')}}</div>
						<div class="box_line"></div>
					</div>
					<img :src="img2" alt="">
				</div>
				<div class="box" style="">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content3')}}</div>
						<div class="box_line"></div>
					</div>
					<img :src="img3" alt="">
				</div>
				<div class="box">
					<div class="box_content">
						<div class="box_ct_title">{{$t('fpcBatch.precisionEquipment.content4')}}</div>
						<div class="box_line"></div>
					</div>
					<img :src="img3" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				img1:process.env.VUE_APP_OSS_URL + '/fpcBatch/line-bg1.jpg',
				img2:process.env.VUE_APP_OSS_URL + '/fpcBatch/line-bg2.jpg',
				img3:process.env.VUE_APP_OSS_URL + '/fpcBatch/line-bg3.jpg',
				img4:process.env.VUE_APP_OSS_URL + '/fpcBatch/line-bg4.jpg',
				bgImg:process.env.VUE_APP_OSS_URL + '/fpcBatch/fpc-equipmen@bg.jpg',
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.precisionEquipment{
			padding: 40px 200px;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell {
			height: 450px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_line{
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.precisionEquipment{
			padding: 40px 200px;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell {
			height: 450px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_line{
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.precisionEquipment{
			padding: 40px 140px;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell {
			height: 450px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_line{
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.precisionEquipment{
			padding: 40px 30px;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 34px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 18px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell {
			height: 450px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 0;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_line{
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.precisionEquipment2{
			padding: 40px 30px;
			height: 100%;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 16px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell2 {
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
			margin-top: 20px;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
	@media only screen and (max-width:767px) {
		.precisionEquipment2{
			padding: 40px 30px;
			height: 100%;
			
			.pe_titleDiv{
				text-align: center;
				
				.pe_td_title{
					font-size: 21px;
					text-align: center;
					font-weight: bold;
					line-height: 58px;
					color: #fff;
				}
				
				.pe_td_text{
					font-size: 16px;
					color: #fff;
					line-height: 28px;
					padding: 1vw 4vw;
				}
			}
		}
		
		.shell2 {
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
			margin-top: 20px;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 40px;
			padding-left: 20px;
			bottom: 20px;
			transform: all 0.3s linear;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
			padding-top: 10px;
		}
		
		.box_line{
			display: block;
			content: '';
			width: 46px;
			height: 4px;
			background: #d1102d;
			position: absolute;
			bottom: -8px;
			left: 20px;
			z-index: 1;
			opacity: 1;
			transition: opacity 0.3s ease-in;
		}
		
		.box:hover .box_content{
			bottom: 40px;
			transform: all 0.3s linear;
		}
		
		.box:hover .box_ct_title ::before{
			
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: #474747;
			opacity: 0.4;
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			background-color: #d1102d;
			opacity: 0.1;
		}
	}
	
</style>