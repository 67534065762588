<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="fpcHots">
				<div class="fh_titleDiv">
					<div class="fh_td_titleDiv">
						<div class="fh_td_title1">{{$t('fpcBatch.fpcHots.title1')}}</div>
						<div class="fh_td_title2">{{$t('fpcBatch.fpcHots.title2')}}</div>
					</div>
					<div class="fh_td_text">{{$t('fpcBatch.fpcHots.text')}}</div>
				</div>
				<div class="fh_left">
					<div>{{$t('fpcBatch.fpcHots.warp.title')}}</div>
					<div class="fh_le_table">
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text1')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text2')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text3')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text4')}}</div>
					</div>
					<div class="fh_le_tableContent">
						<vue-seamless :data="dataList" :class-option="classOption">
							<div v-for="(item, index) in dataList" :key="index" class="fh_le_tc_dataList">
								<div class="fh_le_tc_dl_text">{{ item.userName }}</div>
								<div class="fh_le_tc_dl_text">{{ item.time }}</div>
								<div class="fh_le_tc_dl_text">{{ item.orderNumber }}</div>
								<div class="fh_le_tc_dl_text">{{ item.price }}</div>
							</div>
						</vue-seamless>
					</div>
					<div class="fh_le_inputDiv">
						<el-image style="width: 100%;" :src="require('@/assets/img/tabsContent.png')" :fit="'fill'"></el-image>
					</div>
		
					<div class="fh_le_button" style="cursor: pointer;" @click="jump">{{$t('fpcBatch.fpcHots.warp.content1.button')}}</div>
					
				</div>
				
				<div class="fh_right">
					<div class="fh_ri_title">{{$t('fpcBatch.fpcHots.warp.rightTitle')}}</div>
					<div class="fh_ri_contentDiv">
						<div class="fh_ri_cd_div">
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content1.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content1.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content1.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content1.button')}}</div>
								</div>
							</div>
							
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content2.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content2.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content2.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content2.button')}}</div>
								</div>
							</div>
							
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content3.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content3.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content3.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content3.button')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="fpcHots2">
				<div class="fh_titleDiv">
					<div class="fh_td_titleDiv">
						<div class="fh_td_title1">{{$t('fpcBatch.fpcHots.title1')}}<div class="fh_td_title2">{{$t('fpcBatch.fpcHots.title2')}}</div></div>
					</div>
					<div class="fh_td_text">{{$t('fpcBatch.fpcHots.text')}}</div>
				</div>
				<div class="fh_left">
					<div>{{$t('fpcBatch.fpcHots.warp.title')}}</div>
					<div class="fh_le_table">
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text1')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text2')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text3')}}</div>
						<div class="fh_le_tb_text">{{$t('fpcBatch.fpcHots.warp.gaugeOutfit.text4')}}</div>
					</div>
					<div class="fh_le_tableContent">
						<vue-seamless :data="dataList" :class-option="classOption">
							<div v-for="(item, index) in dataList" :key="index" class="fh_le_tc_dataList">
								<div class="fh_le_tc_dl_text">{{ item.userName }}</div>
								<div class="fh_le_tc_dl_text">{{ item.time }}</div>
								<div class="fh_le_tc_dl_text">{{ item.orderNumber }}</div>
								<div class="fh_le_tc_dl_text">{{ item.price }}</div>
							</div>
						</vue-seamless>
					</div>
					<div class="fh_le_button" style="cursor: pointer;" @click="jump">
						<div class="fh_le_bu_text">{{$t('fpcBatch.fpcHots.warp.content1.button')}}</div>
					</div>
					
				</div>
				
				<div class="fh_right">
					<div class="fh_ri_title">{{$t('fpcBatch.fpcHots.warp.rightTitle')}}</div>
					<div class="fh_ri_contentDiv">
						<div class="fh_ri_cd_div">
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content1.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content1.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content1.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content1.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content1.button')}}</div>
								</div>
							</div>
							
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content" style="cursor: pointer;" @click="jump">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content2.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content2.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content2.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content2.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content2.button')}}</div>
								</div>
							</div>
							
							<div class="fh_ri_cd_di_div">
								<div style="position: relative;">
									<div class="fh_ri_cd_di_di_content">
										<div class="fh_ri_cd_di_ct_title">{{$t('fpcBatch.fpcHots.warp.content3.title')}}</div>
										<div class="fh_ri_cd_di_ct_text">{{$t('fpcBatch.fpcHots.warp.content3.text')}}</div>
									</div>
									<el-image style="width: 100%;" :src="$t('fpcBatch.fpcHots.warp.content3.img')" :fit="'fill'"></el-image>
								</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label1')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label2')}}</div>
								<div class="fh_ri_cd_di_di_label">{{$t('fpcBatch.fpcHots.warp.content3.label3')}}</div>
								
								<div class="fh_ri_cd_di_di_buttonDiv" style="cursor: pointer;" @click="jump">
									<div class="fh_ri_cd_di_di_bd_text">{{$t('fpcBatch.fpcHots.warp.content3.button')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vueSeamless from 'vue-seamless-scroll'
	export default {
		components: {
			vueSeamless
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				classOption: {},
				dataList: [{
						userName: '228**',
						time: '04-17',
						orderNumber: '220438AS16',
						price: '1,124.00'
					},
					{
						userName: '115**',
						time: '04-17',
						orderNumber: '220438AS17',
						price: '199.00'
					},
					{
						userName: '223**',
						time: '04-17',
						orderNumber: '220438AS18',
						price: '99.00'
					},
					{
						userName: '444**',
						time: '04-17',
						orderNumber: '220438AS19',
						price: '130.00'
					},
					{
						userName: '556**',
						time: '04-17',
						orderNumber: '220438AS20',
						price: '219.00'
					},
					{
						userName: '243**',
						time: '04-17',
						orderNumber: '220438AS21',
						price: '99.00'
					}
				],
				options: [{
						lable: '2层',
						value: 1
					},
					{
						lable: '4层',
						value: 2
					},
					{
						lable: '6层',
						value: 3
					},
					{
						lable: '8层',
						value: 4
					}
				],
				optionsNumber:[{
						lable: '25',
						value: 1
				}],
				optionsThickness:[{
					lable: '0.06',
					value: 1
				},
				{
					lable: '0.1',
					value: 2
				},
				{
					lable: '0.13',
					value: 3
				},
				{
					lable: '0.2',
					value: 4
				},
				{
					lable: '0.23',
					value: 5
				},
				{
					lable: '0.35',
					value: 6
				}],
				numberLayers: null,
				number: null,
				thickness: null,
			}
		},

		created() {
			this.classOption = {
				step: 0.4, // 数值越大速度滚动越快
				limitMoveNum: this.dataList.length, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 2000
			}
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//-----------------路由跳转-----------------------
			jump:function(){
				this.$router.push({
					path: '/valuation',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	input[type=text]:focus {
		outline: none;

	}
	
	@media only screen and (min-width:1920px) {
		.fpcHots{
			padding: 40px 200px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				padding: 1vw;
				box-shadow: 3px 7px 18px 0px rgb(255 102 0 / 12%);
				border-radius: 15px 15px 90px 15px;
				border: solid 1px #ededed;
				width: 24vw;
				float: left;
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					margin: 20px 40px;
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				height: 500px;
				margin-left: 20px;
				padding: 30px 20px;
				
				.fh_ri_title{
					padding-left: 24vw;
				}
				
				.fh_ri_contentDiv{
					padding-left: 24vw;
					width: 100%;
					
					.fh_ri_cd_div{
						display: flex;
						width: 100%;
						
						.fh_ri_cd_di_div{
							width: 33%;
							padding: 1vw;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 1vw;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 0.6vw;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 0.6vw;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 14px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 1vw;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.fpcHots{
			padding: 40px 200px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				padding: 1vw;
				box-shadow: 3px 7px 18px 0px rgb(255 102 0 / 12%);
				border-radius: 15px 15px 90px 15px;
				border: solid 1px #ededed;
				width: 24vw;
				float: left;
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					margin: 20px 40px;
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				height: 500px;
				margin-left: 20px;
				padding: 30px 20px;
				
				.fh_ri_title{
					padding-left: 24vw;
				}
				
				.fh_ri_contentDiv{
					padding-left: 24vw;
					width: 100%;
					
					.fh_ri_cd_div{
						display: flex;
						width: 100%;
						
						.fh_ri_cd_di_div{
							width: 33%;
							padding: 1vw;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 1vw;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 0.6vw;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 0.6vw;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 14px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 1vw;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.fpcHots{
			padding: 40px 140px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				padding: 1vw;
				box-shadow: 3px 7px 18px 0px rgb(255 102 0 / 12%);
				border-radius: 15px 15px 90px 15px;
				border: solid 1px #ededed;
				width: 24vw;
				float: left;
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					margin: 20px 40px;
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				height: 500px;
				margin-left: 20px;
				padding: 30px 20px;
				
				.fh_ri_title{
					padding-left: 24vw;
				}
				
				.fh_ri_contentDiv{
					padding-left: 24vw;
					width: 100%;
					
					.fh_ri_cd_div{
						display: flex;
						width: 100%;
						
						.fh_ri_cd_di_div{
							width: 33%;
							padding: 1vw;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 1vw;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 0.6vw;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 0.6vw;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 14px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 1vw;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.fpcHots{
			padding: 40px 30px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 16px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				padding: 1vw;
				box-shadow: 3px 7px 18px 0px rgb(255 102 0 / 12%);
				border-radius: 15px 15px 90px 15px;
				border: solid 1px #ededed;
				width: 24vw;
				float: left;
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 1vw;
					padding: 0.5vw;
					border-radius: 40px;
					text-align: center;
					background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
					background-blend-mode: normal;
					box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
					margin: 20px 40px;
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				height: 500px;
				margin-left: 20px;
				padding: 30px 20px;
				
				.fh_ri_title{
					padding-left: 24vw;
				}
				
				.fh_ri_contentDiv{
					padding-left: 24vw;
					width: 100%;
					
					.fh_ri_cd_div{
						display: flex;
						width: 100%;
						
						.fh_ri_cd_di_div{
							width: 33%;
							padding: 1vw;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 1vw;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 0.6vw;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 0.6vw;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 14px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 1vw;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.fpcHots2{
			padding: 40px 30px;
			margin-top: 60px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 21px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 2vw;
					padding: 1vw 2vw;
					margin: 2vw 0;
					text-align: center;
					display: flex;
					justify-content: center;
					width: 100%;
					
					.fh_le_bu_text{
						background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						border-radius: 40px;
						width: 20vw;
						padding: 1vw;
					}
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				padding: 30px 20px;
				
				.fh_ri_title{
					font-size: 21px;
					font-weight: bold;
					text-align: center;
				}
				
				.fh_ri_contentDiv{
					width: 100%;
					
					.fh_ri_cd_div{
						width: 100%;
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						
						.fh_ri_cd_di_div{
							width: 48%;
							padding: 1vw;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 1vw;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 0.6vw;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 0.6vw;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 14px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 1vw;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.fpcHots2{
			padding: 40px 30px;
			margin-top: 60px;
			background: #fff;
			
			.fh_titleDiv{
				text-align: center;
				
				.fh_td_titleDiv{
					display: flex;
					width: 100%;
					justify-content: center;
					
					.fh_td_title1{
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
						color: #333333;
					}
					
					.fh_td_title2{
						color: red;
						font-size: 34px;
						text-align: center;
						font-weight: bold;
						line-height: 58px;
					}
				}
				
				.fh_td_text{
					font-size: 18px;
					color: #333333;
					line-height: 28px;
					margin-bottom: 20px;
				}
			}
			
			.fh_left{
				z-index: 2;
				background: #fff;
				
				.fh_le_table{
					background-image: linear-gradient(to right, #ffe7d4 6%, #fff);
					width: 100%;
					display: flex;
					margin-top: 10px;
					
					.fh_le_tb_text{
						width: 25%;
						color: #aaaaaa;
						padding: 5px;
						text-align: center;
					}
				}
				
				.fh_le_tableContent{
					height: 150px;
					overflow: hidden;
					
					.fh_le_tc_dataList{
						display: flex;
						background-image: linear-gradient(to right, #ffeddf 6%, #fff);
						display: flex;
						text-align: center;
						
						.fh_le_tc_dl_text{
							width: 25%;
							padding: 5px;
							text-align: center;
						}
					}
				}
				
				.fh_le_inputDiv{
					display: flex;
					width: 100%;
					align-items: center;
					
					.fh_le_id_div{
						padding-top: 10px;
						width: 50%;
					}
				}
				
				.fh_le_button{
					color: #fff;
					font-size: 2vw;
					padding: 1vw 2vw;
					margin: 2vw 0;
					text-align: center;
					display: flex;
					justify-content: center;
					width: 100%;
					
					.fh_le_bu_text{
						background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
						background-blend-mode: normal;
						box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
						border-radius: 40px;
						width: 20vw;
						padding: 1vw;
					}
				}
			}
			
			.fh_right{
				width: 100%;
				background: #ffedde;
				padding: 30px 20px;
				
				.fh_ri_title{
					font-size: 21px;
					font-weight: bold;
					text-align: center;
				}
				
				.fh_ri_contentDiv{
					width: 100%;
					
					.fh_ri_cd_div{
						width: 100%;
						display: flex;
						justify-content: center;
						flex-wrap: wrap;
						
						.fh_ri_cd_di_div{
							padding: 10px;
							
							.fh_ri_cd_di_di_content{
								position: absolute;
								color: #fff;
								bottom: 0;
								z-index: 3;
								padding-left: 10px;
								
								.fh_ri_cd_di_ct_title{
									font-size: 12px;
									font-weight: bold;
								}
								
								.fh_ri_cd_di_ct_text{
									font-size: 12px;
									padding-top: 5px;
									padding-bottom: 10px;
								}
							}
							
							.fh_ri_cd_di_di_label{
								padding-top: 5px;
								font-size: 12px;
							}
							
							.fh_ri_cd_di_di_buttonDiv{
								margin-top: 20px;
								font-size: 12px;
								line-height: 22px;
								
								.fh_ri_cd_di_di_bd_text{
									color: #fff;
									font-size: 10px;
									padding: 0.5vw;
									border-radius: 40px;
									text-align: center;
									background-image: linear-gradient(249deg, rgba(240, 56, 60, 0.9) 0%, rgba(240, 56, 60, 0.9) 100%), linear-gradient(#4e4e4e, #4e4e4e);
									background-blend-mode: normal;
									box-shadow: 0px 5px 15px 0px rgb(245 80 53 / 40%);
									margin: 10px 40px;
								}
							}
						}
					}
				}
			}
		}
		
		.cd_ct_pcb_input1 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_x {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
		
		.cd_ct_pcb_input2 {
			width: 62px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			border: 1px solid #d6d6d6;
			background: #fff;
		}
		
		.cd_ct_pcb_cm {
			display: inline-block;
			font-size: 16px;
			border: 1px solid #d6d6d6;
			text-align: center;
			background: #efefef;
			padding: 0 10px;
			display: flex;
			align-items: center;
		}
	}
	
</style>
